import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';
import NumericInput from '@/components/DSE/NumericInput';
import Select from '@/components/DSE/Select';
import MultiLanguageInput from '@/components/DSE/MultiLanguageInput';
import Switch from '@/components/DSE/Switch';

export function getFormDefinition(vue) {
  return {
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    parentProductCategoryID: {
      type: Select,
      defaultValue: null,
      props: {
        caption: 'common.productCategory',
        valueField: 'id',
        labelField: 'name',
        loading: vue.$store.getters['productCategory/isLoading'],
        options: vue.$store.getters['productCategory/loadedItems']
      }
    },
    position: {
      type: NumericInput,
      defaultValue: 0,
      props: {
        type: 'number',
        precision: 0,
        caption: 'common.position'
      }
    },
    positionOnTicket: {
      type: NumericInput,
      defaultValue: 0,
      props: {
        type: 'number',
        precision: 0,
        caption: 'common.positionOnTicket'
      }
    },
    ageRestriction: {
      type: MultiLanguageInput,
      props: {
        min: 0,
        max: 999,
        precision: 0,
        step: 1,
        caption: 'common.ageRestriction',
        type: 'number',
        isNumeric: true
      }
    },
    showLargeInSiteYN: {
      type: Switch,
      defaultValue: false,
      props: {
        caption: 'product.showLargeInSiteYN',
        activeText: 'common.yes',
        inactiveText: 'common.no'
      }
    },
    onlineYN: {
      type: Switch,
      defaultValue: false,
      props: {
        activeText: 'common.yes',
        inactiveText: 'common.no'
      }
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return {
    ...item
  };
}
